import { useEffect, useState } from "react";
import { Checkbox, Autocomplete, TextField } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { getAllNewOrganizations } from "../../../api/OrganizationAPI";
import { validateSignUpForm } from "../../../helpers/FormHelpers";
import { AuthErrors, NewSignUpFormData } from "../../../interfaces";
import { AuthSubmit } from "../shared/submit/AuthSubmit";
import {
  AuthTextfield,
  AuthTextfieldTypes,
} from "../shared/textfield/AuthTextfield";
import { TermsOfService } from "../shared/terms/TermsOfService";
import { checkMobileIosBrowser } from "../../../utils/mobileConfigurations";
import { MobileAddToHomeScreen } from "../../shared/iOSMobileAdd";
import { authenticationRequest } from "../../../store/auth/authSlice";
import { useAppDispatch } from "../../../store/hooks";

import { newAccountToken } from "../../../interfaces";

interface SignUpFormNew {
  inviteData?: newAccountToken;
  onSubmit: (signUpForm: NewSignUpFormData) => Promise<void>;
}

// todo: implement formik. validation, control states can be done through that
// instead of custom handlers
export const SignUpFormNew = ({ inviteData, onSubmit }: SignUpFormNew) => {
  const [signUpForm, setSignUpForm] = useState<NewSignUpFormData>({
    firstName: inviteData ? inviteData.firstName : "",
    lastName: inviteData ? inviteData.lastName : "",
    email: inviteData ? inviteData.email : "",
    setPass: "",
    confirmPass: "",
    acceptedTerms: false,
    organization: null,
    marketingEmails: true,
    other_classification: null,
  });
  const [mobileIos, setMobileIos] = useState(false);
  const [availableOrgs, setAvailableOrgs] = useState([]);
  const [formErrors, setErrors] = useState(null);
  const [formErrorMessages, setErrorMessages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uniqueName = queryParams.get("uniqueName");
  const promoCode = queryParams.get("promoCode");

  useEffect(() => {
    if (promoCode) {
      const formattedpromo = promoCode.toUpperCase();
      setSignUpForm({
        ...signUpForm,
        other_classification: formattedpromo,
      })
    }
  }, [])

  useEffect(() => {
    if (uniqueName) {
      const formattedName = uniqueName.toLowerCase();
      const currentOrg = availableOrgs.find(a => a.unique_name === formattedName);
      if (currentOrg) {
        setSignUpForm({
          ...signUpForm,
          organization: currentOrg,
        })
      }
    }
  }, [availableOrgs])

  const handleInputChange = (type: AuthTextfieldTypes, val: string) => {
    var tempData = { ...signUpForm };
    switch (type) {
      case AuthTextfieldTypes.FirstName:
        tempData.firstName = val;
        break;
      case AuthTextfieldTypes.LastName:
        tempData.lastName = val;
        break;
      case AuthTextfieldTypes.Email:
        tempData.email = val;
        break;
      case AuthTextfieldTypes.SetPassword:
        tempData.setPass = val;
        break;
      case AuthTextfieldTypes.ConfirmPassword:
        tempData.confirmPass = val;
        break;
      default:
        tempData = signUpForm;
    }

    setSignUpForm(tempData);
  };

  const handleErrors = (errors: AuthErrors[]) => {
    setErrors(errors);
    setErrorMessages(
      errors.map((e) => (
        <h1 key={e} className="auth-form-error">
          * {e}
        </h1>
      ))
    );
  };

  const handleSubmit = async () => {
    const errors = validateSignUpForm(signUpForm, true);
    if (errors !== null) {
      handleErrors(errors);
      return;
    }
    setIsLoading(true);

    try {
      await onSubmit(signUpForm);
      await handleSuccessfulRequest();
    } catch {
      setIsLoading(false);
      handleErrors([AuthErrors.ConsumeError]);
    }
  };

  const handleSuccessfulRequest = async () => {
    setErrors(null);
    setErrorMessages(null);
    const baseUrl = process.env.NODE_ENV === "production"
    ? "https://www.advancedpension.com"
    : process.env.NODE_ENV === "test"
    ? "https://staging2.advancedpension.com"
    : "http://localhost:3030";

    try {
      await dispatch(
        authenticationRequest({
          email: signUpForm.email,
          password: signUpForm.setPass,
        })
      );
      window.location.href = `${baseUrl}/verify`;
    } catch {
      setIsLoading(false);
    }
  };

  const handlePreviousAccount = () => {
    history.push("/login");
  };

  const retrieveOrganizations = async () => {
    try {
      setIsLoading(true);
      // default to CA only for now
      const orgs = await getAllNewOrganizations("California");
      setAvailableOrgs(orgs["California"]);
    } catch {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    retrieveOrganizations();
    setMobileIos(checkMobileIosBrowser());
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="auth-form-container">
      <MobileAddToHomeScreen
        open={mobileIos}
        onClose={() => setMobileIos(false)}
      />
      <div className="auth-form-content">
        <div>
          <h1 className="auth-form-title">
            {inviteData ? "Finish registration" : "Create an account"}
          </h1>
          <div className="auth-form-title-underline"></div>
        </div>
        {formErrors && formErrorMessages}
        <div>
          <AuthTextfield
            key="first name"
            type={AuthTextfieldTypes.FirstName}
            formErrors={formErrors}
            value={signUpForm.firstName}
            onChange={handleInputChange}
          />
          <AuthTextfield
            key="last name"
            type={AuthTextfieldTypes.LastName}
            formErrors={formErrors}
            value={signUpForm.lastName}
            onChange={handleInputChange}
          />
          <AuthTextfield
            key="email"
            type={AuthTextfieldTypes.Email}
            formErrors={formErrors}
            value={signUpForm.email}
            onChange={handleInputChange}
            disabled={!!inviteData}
          />
          <AuthTextfield
            key="set password"
            type={AuthTextfieldTypes.SetPassword}
            formErrors={formErrors}
            value={signUpForm.setPass}
            onChange={handleInputChange}
          />
          <AuthTextfield
            key="confirm pass"
            type={AuthTextfieldTypes.ConfirmPassword}
            formErrors={formErrors}
            value={signUpForm.confirmPass}
            onChange={handleInputChange}
          />
          {/* <Autocomplete
                        disablePortal
                        sx={{ marginTop: '0.75rem' }}
                        options={Object.keys(organizations).sort()}
                        onChange={(e, newValue) => handleClick(newValue)}
                        renderInput={(params) => <TextField label='State' {...params} />}
                    /> */}
          <Autocomplete
            options={availableOrgs || []}
            sx={{ marginTop: "0.75rem" }}
            value={signUpForm.organization}
            getOptionLabel={(option) => `${option.display_name}`}
            onChange={(e, newValue) =>
              setSignUpForm({ ...signUpForm, organization: newValue })
            }
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) =>
                option.display_name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              );
            }}
            renderInput={(Props) => (
              <TextField
                label="Organization Name"
                {...Props}
                variant="outlined"
              />
            )}
          />
          <div style={{ display: "flex" }}>
            <Checkbox
              color="primary"
              checked={signUpForm.acceptedTerms}
              onChange={(e) =>
                setSignUpForm({
                  ...signUpForm,
                  acceptedTerms: e.target.checked,
                })
              }
            />
            <p>
              I have read and agree to the
              <span
                style={{ fontWeight: "bold", color: "blue" }}
                onClick={() => setShowTerms(true)}
              >
                &nbsp;Terms Of Service
              </span>
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <Checkbox
              color="primary"
              checked={signUpForm.marketingEmails}
              onChange={(e) =>
                setSignUpForm({
                  ...signUpForm,
                  marketingEmails: e.target.checked,
                })
              }
            />
            <p>
            Yes! I want to stay in the loop with updates about my pension and exclusive product offers.
            </p>
          </div>
        </div>
        <AuthSubmit isLoading={isLoading} onSubmit={handleSubmit} />
        <div className="forgot-password-button" onClick={handlePreviousAccount}>
          Already Have An Account?
        </div>
      </div>
      {showTerms && (
        <TermsOfService
          onClose={() => setShowTerms(false)}
          onAccept={() => {
            setShowTerms(false);
            setSignUpForm({ ...signUpForm, acceptedTerms: true });
          }}
        />
      )}
    </div>
  );
};
