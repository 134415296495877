import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { selectCompleteSurvey, selectIsAdmin, selectLoggedInStatus, selectIsValidated } from '../../store/auth/authSlice';

interface AdminRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const AdminRoute: React.FC<AdminRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isAdmin = useSelector(selectIsAdmin);
  const isLoggedIn = useSelector(selectLoggedInStatus);
  const hasCompletedSurvey = useSelector(selectCompleteSurvey);
  const hasVerifiedEmail = useSelector(selectIsValidated);

  const baseUrl = process.env.NODE_ENV === "production"
  ? "https://www.advancedpension.com"
  : process.env.NODE_ENV === "test"
  ? "https://staging2.advancedpension.com"
  : "http://localhost:3030"

  const handleRedirect = () => {
      if (isLoggedIn) {
        if (!hasVerifiedEmail) {
          window.location.href = `${baseUrl}/verify`;
        }
        if (!hasCompletedSurvey) {
          window.location.href = `${baseUrl}/onboarding`;
        }
        return "/overview"
      } else {
        return "/login"
      }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: handleRedirect(),
            }}
          />
        )
      }
    />
  );
}

export default AdminRoute;
