export const getBackendUrlForPath = (path: string) => {
  return process.env.REACT_APP_LOCAL_BACKEND + path;
};

export enum API_ROUTE {

  // auth
  ACCOUNT_LOGIN = "/auth/oldLogin",
  ACCOUNT_GET_TERMS = "/auth/getLatestTerms",
  ACCOUNT_ACCEPT_TERMS = "/auth/acceptTerms",
  CREATE_ACCOUNT = "/auth/createAccount",
  CREATE_SUBSCRIBER = "/auth/createSubscriber",
  SEND_RESET_PASS = "/auth/sendResetPassword",
  AUTHENTICATE_PASS_RESET = "/auth/getPasswordReset",
  AUTHENTICATE_ACCOUNT_TOKEN = "/auth/getAccountToken",
  CONSUME_RESET_PASS = "/auth/consumeResetPassword",
  ACCOUNT_CHANGE_PASS = "/auth/changePassword",
  ACCOUNT_VALIDATE = "/auth/validate",
  ACCOUNT_REMOVE = "/auth/remove",
  ACCOUNT_REVALIDATE = "/auth/resendValidation",
  ACCOUNT_LOGOUT = "/auth/logout",

  // profile
  SET_SURVEY_ANSWER = "/profile/answer",
  SET_PROFILE_DATA = "/profile/updateUserData",
  SET_VARIABLE = "/profile/saveVariable",
  SET_VARIABLES = "/profile/saveVariables",
  COMPLETE_SURVEY = "/profile/completeSurvey",
  SET_PROFILE_INTRO = "/profile/completeDashboardIntro",
  GET_PROFILE_DATA = "/profile/getUser",
  SET_HIRE_DATE = "/profile/setHireDate",
  SET_PLAN_BENEFIT = "/profile/setPlanBenefit",
  CREATE_ADDITIONAL_CONFIG = "/profile/createAdditionalConfiguration",
  DELETE_ADDITIONAL_CONFIG = "/profile/deleteAdditionalConfiguration",

  // forecast
  FORECAST = "/forecast",
  FORECAST_CALCULATE = "/forecast/calculate",
  FORECAST_CALCULATE_SAVE = "/forecast/calculateSave",
  FORECAST_EDIT = "/forecast/edit",
  FORECAST_PROFILE = "/forecast/profile",
  FORECAST_SAVE = "/forecast/save",

  // survey
  GET_SURVEY = "/survey/getSurvey",
  GET_ALL_QUESTIONS = "/survey/allQuestions",

  // organization
  CREATE_INVITATION = "/organization/createInvitation",
  RESEND_INVITATION = "/organization/resendInvitation",
  GET_INVITATION = "/organization/getInvitation?key=",
  GENERATE_ORGANIZATION_INVITATION = "/organization/generateOrganizationInvitation",
  ACTIVATE_ORGANIZATION_INVITATION = "/organization/activateOrganizationInvitation",
  DEACTIVATE_ORGANIZATION_INVITATION = "/organization/deactivateOrganizationInvitation",
  GET_ORGANIZATION_INVITATION = "/organization/getOrganizationInvitation?key=",
  GET_INVITATION_BY_ORG = "/organization/getInvitationByOrg?id=",
  GET_ORGANIZATION_INVITATION_KEY = "/organization/getOrganizationInvitationKey",
  CONSUME_INVITATION = "/organization/consumeInvitation",
  EDIT_USER = "/organization/editUser",
  GET_ORGANIZATION = "/organization/getOrganization",
  GET_NEW_ORGANIZATIONS = "/organization/getNewOrganizations",
  SUPER_ADMIN_GET_SIGNUP_INVITATION = "/organization/getSignupInvitation",
  SUPER_ADMIN_APPROVE_SIGNUP = "/organization/approveSignup",
  SUPER_ADMIN_DECLINE_SIGNUP = "/organization/declineSignup",

  // super
  SUPER_ADMIN_GET_USER_BY_ID = "/super/getUserById?userId=",
  SUPER_ADMIN_LOAD_DASHBOARD = "/super/loadDashboard",
  SUPER_ADMIN_EDIT_USER = "/super/editUser",
  SUPER_ADMIN_GET_VARIABLES = "/super/getAllVariables",
  SUPER_ADMIN_CREATE_ORGANIZATION = "/super/createOrganization",
  SUPER_ADMIN_EDIT_ORGANIZATION = "/super/editOrganizationData",

  // pension
  PENSION_SET_RECIPROCITY = "/pension/editReciprocity",

  // subscription
  SUBSCRIPTION_CREATE = "/subscription/create",
  SUBSCRIPTION_RENEW = "/subscription/renew",
  SUBSCRIPTION_GET_SESSION = "/subscription/stripeCheckout",
  SUBSCRIPTION_CANCEL = "/subscription/cancel",
  SUBSCRIPTION_WHITELIST_PROMO = "/subscription/whitelistpromo",

  // email
  EMAIL_SIGNUP = "/email/signup"
}

export const apiDefaultOptions = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
};
