import React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCompleteSurvey, selectIsAdmin, selectLoggedInStatus, selectIsValidated } from '../../store/auth/authSlice';
import { UserPage, UserPageRoute } from '../user/UserPage';
import EmailRemoval from '../user/overview/EmailRemoval';
import EmailValidation from '../user/overview/EmailValidation';

interface PublicRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const PublicRoute: React.FC<PublicRouteProps> = ({
  path,
  component: Component,
  ...rest
}) => {
  const isLoggedIn = useSelector(selectLoggedInStatus);
  const isAdmin = useSelector(selectIsAdmin);
  const hasCompletedSurvey = useSelector(selectCompleteSurvey);
  const hasVerifiedEmail = useSelector(selectIsValidated);
  const baseUrl = process.env.NODE_ENV === "production"
    ? "https://www.advancedpension.com"
    : process.env.NODE_ENV === "test"
    ? "https://staging2.advancedpension.com"
    : "http://localhost:3030";

  const handleRedirect = () => {
    if (isAdmin) {
      return "/superadmin";
    }
    if (!hasVerifiedEmail) {
      window.location.href = `${baseUrl}/verify`;
    }
    if (!hasCompletedSurvey) {
      window.location.href = `${baseUrl}/onboarding`;
    }

    return "/overview";
  };

  // redirect if main path and it's going to /home page
  if (!isLoggedIn && path === "/") {
    window.location.href = `${baseUrl}/home`;
    return null;
  }

  // todo: need to fix how we render paths
  if (isLoggedIn && path === "/subscribe") {
    return <UserPage page={UserPageRoute.Subscribe} />
  } else if (isLoggedIn && path === "/success") {
    return <UserPage page={UserPageRoute.Subscribe} />
  } else if (isLoggedIn && path.includes("/remove")) {
    return (
      <Route
        path="/remove/:userId/:token"
        render={(props) => <EmailRemoval {...props} />}
      />
    );
  } else if (isLoggedIn && path.includes("/validate")) {
    return (
      <Route
        path="/validate/:userId/:token"
        render={(props) => <EmailValidation {...props} />}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: handleRedirect(),
            }}
          />
        )
      }
    />
  );
}

export default PublicRoute;
