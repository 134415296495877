import React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCompleteSurvey, selectIsAdmin, selectLoggedInStatus, selectIsValidated } from "../../store/auth/authSlice";

interface PrivateRouteProps extends RouteProps {
  children: (props: RouteComponentProps) => React.ReactNode;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  path,
  ...rest
}) => {
  const isLoggedIn = useSelector(selectLoggedInStatus)
  const isAdmin = useSelector(selectIsAdmin)
  const hasCompletedSurvey = useSelector(selectCompleteSurvey)
  const hasVerifiedEmail = useSelector(selectIsValidated)

  const baseUrl = process.env.NODE_ENV === "production"
    ? "https://www.advancedpension.com"
    : process.env.NODE_ENV === "test"
    ? "https://staging2.advancedpension.com"
    : "http://localhost:3030"

  const handleRedirect = () => {
    if (!isLoggedIn) {
      window.location.href = `${baseUrl}/login`;
      return;
    } else if (isAdmin) {
      return "/superadmin"
    } else if (!hasVerifiedEmail) {
      window.location.href = `${baseUrl}/verify`;
    } else if (!hasCompletedSurvey) {
      window.location.href = `${baseUrl}/onboarding`;
    }
    window.location.href = `${baseUrl}/home`;
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn || isAdmin) {
          return <Redirect to={{ pathname: handleRedirect() }} />;
        }

        if (path === "/verify") {
          return !hasVerifiedEmail ? children(props) : <Redirect to={{ pathname: handleRedirect() }} />;
        }

        return hasVerifiedEmail ? children(props) : <Redirect to={{ pathname: handleRedirect() }} />;
      }}
    />
  );
}

export default PrivateRoute;
